.page {
  width: 100%;
  padding: 40px 0;

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 35px 30px;

    .left_block {
      max-width: 504px;

      .gift_sum {
        color: var(--white);

        h4 {
          font-size: 40px;
          font-weight: 700;
          line-height: 52px;
          letter-spacing: 0px;
          text-align: center;

          span {
            display: block;

            span {
              display: inline-block;
            }
          }
        }
        h3 {
          padding: 15px 40px;
          margin-top: 56px;

          font-size: 78px;
          font-weight: 800;
          line-height: 78px;
          letter-spacing: 0px;
          text-align: center;

          border-radius: 10px;
          background: #72BF44;
        }
        h5 {
          padding: 10px;
          margin-top: 15px;
          color: #323232;

          font-size: 34px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: 0px;
          text-align: center;

          border-radius: 10px;
          background: #EECE42;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .right_block {
      form {
        width: 100%;
        max-width: 500px;
        height: auto;
        border-radius: 10px;
        gap: 15px;
        padding-right: 30px;

        button {
          width: 100%;
        }
      }
    }

    .bg {
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      width: 75%;
      height: 100%;
      box-shadow: 0px 2px 14px 0px #00000040;
      background: #F3F4F6;
    }
  }
}

@media (max-width: 1440px) {
  .page {
    width: 100%;
    padding: 40px 0;

    &__container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      padding: 35px 30px;

      .left_block {
        max-width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      .right_block {
        form {
          width: 100%;
          max-width: 500px;
          height: auto;
          border-radius: 10px;
          gap: 15px;
          padding-right: 30px;
          margin-right: 60px;

          button {
            width: 100%;
          }
        }
      }

      .bg {
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        width: 75%;
        height: 100%;
        margin-right: 30px;
        box-shadow: 0px 2px 14px 0px #00000040;
        background: #F3F4F6;
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    padding: 0;
    background: #F3F4F6;

    &__container {
      width: 100%;
      gap: 32px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .left_block {
        max-width: 100%;
        min-width: 100%;
        display: flex;
        justify-content: center;
        flex: 1;

        img {
          max-width: 549px;
        }
      }

      .right_block {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        form {
          height: auto;
          padding: 0;
          margin: 0;
        }
      }

      .bg {
        display: none;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {

      .left_block {
        width: 100%;
        flex-wrap: wrap;

        .gift_sum {
          color: var(--white);

          h4 {
            color: #323232;

            font-size: 24px;
            font-weight: 700;
            line-height: 31px;
            letter-spacing: 0px;
            text-align: center;

            span {
              span {
                display: block;
              }
            }
          }

          h3 {
            padding: 5px 40px;
            margin-top: 20px;

            font-size: 40px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0px;
            text-align: center;

            border-radius: 10px;
            background: #72BF44;
          }

          h5 {
            padding: 10px;
            margin-top: 15px;
            color: #323232;

            font-size: 18px;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0px;
            text-align: center;

            border-radius: 10px;
            background: #EECE42;
          }
        }
      }

      .right_block {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        form {
          min-width: 100%;
          height: auto;

          h3 {
            font-size: 26px;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: 0em;
          }
        }
      }

      .bg {
        display: none;
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    &__container {
      gap: 0;

      .left_block {
        padding-bottom: 10px;

        img {
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .page {
    &__container {
      gap: 0;
      padding: 32px 16px;

      .left_block {
        padding-bottom: 10px;

        .gift_sum {
          h3 {
            margin-top: 12px;
          }
          h5 {
            margin-top: 5px;
          }
        }

        img {
          margin-top: 20px;
        }
      }

      .right_block {
        form {
          h3 {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.1px;
          }

          h4 {
            color: #272D37;

            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
          }

          label {
            span {
              color: #272D37;

              font-size: 10px;
              font-weight: 500;
              line-height: 15px;
              letter-spacing: 0px;
            }
          }
        }
      }
    }
  }
}