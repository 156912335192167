.modal {
  &__title {
    color: var(--dark-blue);

    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: -0.1px;
    text-align: center;
  }
  &__form {
    margin-top: 0;

    h3 {
      max-width: calc(100% - 30px);
      margin-right: 15px;

      color: var(--dark-blue);

      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: -0.1px;
      text-align: center;
    }

    button {
      width: 100% !important;
      height: 46px !important;
      padding: 12px 18px !important;
    }
  }
}

@media (max-width: 655px) {
  .modal {
    &__form {
      margin-top: 0;
      padding: 15px;
      overflow: auto;
      max-height: 100%;
    }
  }
}
@media (max-width: 500px) {
  .modal {
    &__form {
      h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.1px;
        text-align: left;
      }
    }
  }
}