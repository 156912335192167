.modal {
  &__title {
    color: #232323;

    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0;
    text-align: left;
  }
  &__content {
    max-width: 352px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 12px;
    text-align: center;
  }
  &__desc {
    color: #475467;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }
}

.button {
  width: 100%;
  align-self: center;
  margin-top: 20px;
  background: #72BF44;
  border: 3px solid #72BF44;

  &:hover {
    border-color: #5cb629;
    background-color: #5cb629;
    color: var(--white);
  }
}