.page {
  width: 100%;
  padding: 64px 0;
  background-color: #F3F4F6;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 64px;

    .left_block {
      max-width: 584px;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        .title {
          margin-bottom: 30px;
          text-transform: uppercase;
          color: #323232;

          font-size: 48px;
          font-weight: 700;
          line-height: 62px;
          letter-spacing: -0.02em;

          span {
            display: block;

            span {
              color: #72BF44;
            }
          }
        }

        .desc {
          max-width: 455px;
          margin-bottom: 20px;
          color: #5F6D7E;

          font-size: 17px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0px;
        }

        button {
          align-self: start;
          margin-top: 10px;
        }

        .rating {
          margin-top: 30px;
        }
      }
    }

    .right_block {
      max-width: 568px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 0 30px;

      .left_block {
        .content {
          .rating {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
@media (max-width: 1250px) {

}
@media (max-width: 1080px) {

}
@media (max-width: 992px) {
  .page {
    &__container {
      gap: 16px;

      .left_block {
        .content {
          .title {
            font-size: 4.5vw;
            line-height: 5.5vw;
          }

          .desc {
            font-size: 1.8vw;
            line-height: 2vw;
          }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    padding: 32px 0;

    &__container {
      align-items: start;
      flex-wrap: wrap;

      .left_block {
        order: 1;

        .content {
          .title {
            margin-bottom: 30px;
            text-transform: uppercase;
            color: #323232;

            font-size: 48px;
            font-weight: 700;
            line-height: 62px;
            letter-spacing: -0.02em;

            span {
              display: block;

              span {
                color: #72BF44;
              }
            }
          }

          .desc {
            max-width: 455px;
            margin-bottom: 20px;
            color: #5F6D7E;

            font-size: 17px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0px;
          }

          button {
            margin-top: 10px;
          }

          .rating {
            margin-top: 30px;
          }
        }
      }

      .right_block {
        order: 0;
        max-width: 568px;
        margin: 0 auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .page {
    &__container {
      .left_block {
        .content {
          .title {
            font-size: 28px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0px;
          }

          .desc {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.1px;
          }

          button {
            width: 100%;
            margin-top: 10px;
          }

          .rating {
            margin-top: 30px;

            p {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: -0.1px;
            }
          }
        }
      }

      .right_block {
        max-width: 568px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}