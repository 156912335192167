.button {
  width: 270px;
  height: 46px;
  padding: 12px 18px;
  border-radius: 6px;
  gap: 6px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  background: none;
  color: var(--white);
  cursor: pointer;
  transition: 0.3s;

  text-align: center;
  text-transform: uppercase;
  align-self: center;

  &.default {
    background: #72BF44;
    border: 1px solid #72BF44;

    width: 210px;
    height: 46px;
    padding: 12px 18px;

    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 1.5px;

    &:hover {
      background: #29980f;
      border-color: #29980f;
    }
  }

  &.variant2 {
    width: 237px;
    background: var(--white);
    border: 1px solid #72BF44;
    color: #272D37;

    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.5px;

    &:hover {
      background: #29980f;
      border-color: #29980f;
      color: var(--white);
    }
  }

  &.variant3 {
    width: 310px;
    height: 60px;

    background: #72BF44;
    border: 1px solid #72BF44;
    color: var(--white);

    padding: 18px 23px;
    border-radius: 4px;
    gap: 10px;

    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;

    &:hover {
      background: #29980f;
      border-color: #29980f;
      color: var(--white);
    }
  }
}

.icon {

  img {
    width: 20px;
    height: 20px;
  }

  &__left {
    padding-right: 5px;
    margin-bottom: -3px;
  }
  &__right {
    padding-left: 5px;
    margin-bottom: -3px;
  }
}