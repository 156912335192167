.page {
  width: 100%;
  padding: 64px 0;
  margin-top: 32px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;

    .title {
      color: #272D37;

      font-size: 36px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: -0.02em;
      text-align: center;
    }

    .content {
      .cards {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 32px;
        margin-top: 64px;

        .card {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 259px;

          &__num {
            width: 62.35px;
            height: 62.35px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border-radius: 50%;
            border: 2.08px solid #CDCDCD;
            background: var(--white);
            color: #CDCDCD;

            p {
              padding-left: 2px;

              font-size: 31px;
              font-weight: 600;
              line-height: 41px;
              letter-spacing: 3.1px;
            }

            &.num_active {
              border: 2.08px solid #72BF44;
              background: #72BF44;
              color: var(--white);
            }
          }

          &__desc {
            margin-top: 32px;
            color: #272D37;

            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.1px;
            text-align: center;
          }

          &:after {
            z-index: -1;
            content: "";
            position: absolute;
            top: 30px;
            left: -20px;
            width: 100%;
            height: 1px;
            border-top: 1.04px dashed #ACACAC;
            transform: translateX(-50%);
          }

          &:first-child:after {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 0 30px;
    }
  }
}
@media (max-width: 1280px) {
  .page {
    &__container {
      flex-wrap: wrap;

      .left_block {
        max-width: 100%;

        .title {
          text-align: center;

          font-size: 4vw;
          line-height: 6vw;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    &__container {
      flex-wrap: wrap;
      .left_block {
        max-width: 100%;
        .title {
          font-size: 5vw;
          line-height: 6vw;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .page {
    padding: 32px 0;
    margin-top: 0;

    &__container {
      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: center;
      }

      .content {
        .cards {
          display: flex;
          justify-content: start;
          align-items: center;
          flex-wrap: wrap;
          gap: 40px;
          margin-top: 32px;

          .card {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            min-height: 42px;

            &__num {
              min-width: 30px;
              width: 30px;
              min-height: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              border-radius: 50%;
              border: 2.08px solid #CDCDCD;
              background: var(--white);
              color: #CDCDCD;

              p {
                padding-top: 2px;
                padding-left: 1px;

                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 1.5px;
                text-align: left;
              }
            }

            &__desc {
              margin-top: 0;
              margin-left: 32px;
              color: #272D37;

              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: -0.1px;
              text-align: left;
            }

            &:after {
              z-index: -1;
              content: "";
              position: absolute;
              top: 29px;
              left: 15px;
              width: 1px;
              height: calc(100% + 30px);
              border-right: 1.04px dashed #CDCDCD;
              //transform: rotate(90deg);
            }

            &:first-child:after {
              display: block;
            }
            &:last-child:after {
              display: none;
            }
          }
        }

        button {
          width: 100%;
          margin-top: 32px;
        }
      }
    }
  }
}