.page {
  width: 100%;
  padding: 30px 0;

  background-color: var(--white);

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 64px;

    .left_block {
      max-width: 568px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .right_block {
      max-width: 584px;

      .card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        background-color: var(--white);
        max-width: 584px;

        &__title {
          margin-bottom: 10px;
          color: #323232;

          font-size: 36px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;
        }

        &__desc {
          margin-top: 20px;
          color: #323232;

          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.1px;
          text-align: left;

          span {
            color: #72BF44;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 0 30px;
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      flex-wrap: wrap;
      gap: 32px;

      .left_block {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 500px) {
  .page {
    &__container {
      .right_block {
        .card {
          &__title {
            font-size: 28px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.02em;
          }

          &__desc {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.1px;
          }
        }
      }
    }
  }
}