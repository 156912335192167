.star_rating {
  display: flex;
  align-items: center;
  gap: 10px;

  &__desc1 {
    color: #272D37;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
    text-align: left;
  }
  &__stars {
    img {
      padding-right: 5px;

      &:last-child {
        padding: 0;
      }
    }
  }
  &__desc2 {
    color: #323232;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    text-align: left;
  }
}