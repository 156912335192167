.modal {
  &__container {
    max-width: 488px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 12px;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 30px 30px 30px;
  }

  &__image {
    width: 100%;
    height: 205px;
  }

  &__title {
    color: #323232;
    margin-top: 30px;

    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
  }

  &__desc {
    color: #323232;
    margin-top: 10px;

    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }

  &__close_icon {
    top: 26px;
    right: 26px;
  }
}

.button {
  width: 100% !important;
  align-self: center;
  margin-top: 20px;
  background: #72BF44;
  border: 3px solid #72BF44;
  box-shadow: 0px 1px 2px 0px #1018280A;

  &:hover {
    border-color: #5cb629;
    background-color: #5cb629;
    color: var(--white);
  }
}

.modal {
  &__container_modal {
    width: 100%;
    max-width: 100%;

    height: calc(100vh - 70px);

    .modal__content {
      height: 100%;
    }

    .button {
      align-self: flex-end;
      margin-top: auto;
    }
  }
}