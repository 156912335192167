.header {
  .layout {
    background: var(--white);
    border-bottom: 1px solid #EAEBF0;

    &__modal {
      div {
        justify-content: center;
      }
    }
  }

  .left_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }
  .right_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .call_info {
      max-width: 144px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      p {
        color: #5F6D7E;

        font-family: var(--font-family-inter);
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: -0.02em;
        text-align: right;

      }
    }
  }

  &--fixed {
    z-index: 3;
    width: 100%;
    position: fixed;
  }
  &__left_icon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 18px;
    left: 20px;
    cursor: pointer;
  }
}

@media (max-width: 992px) {
  .header {
    .left_block {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
    .right_block {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
  }
}

@media (max-width: 900px) {
  .header {
    .left_block {
      gap: 15px;
    }
    .right_block {
      gap: 15px;

      .call_info {
        max-width: 90px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .header {
    .right_block {
      .call_info {
        max-width: 144px;
      }
    }
  }
}

@media (max-width: 450px) {
  .header {
    .right_block {
      .call_info {
        max-width: 144px;
      }
    }
  }
}